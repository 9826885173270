var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "wap-content" }, [
      _c("div", { staticClass: "query-wrapper" }, [
        _c(
          "div",
          { staticClass: "query-item" },
          [
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "100px" },
                attrs: { placeholder: "全部商品", filterable: "" },
                on: {
                  change: function () {
                    _vm.goodsData = []
                    _vm.goodsParams.page = 1
                    _vm.getQueryGoodsList()
                  },
                },
                model: {
                  value: _vm.goodsParams.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.goodsParams, "type", $$v)
                  },
                  expression: "goodsParams.type",
                },
              },
              [
                _c("el-option", { attrs: { label: "全部商品", value: "" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "秒杀商品", value: "2" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "拼团商品", value: "3" } }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-input", {
              staticStyle: { width: "150px" },
              attrs: {
                placeholder: "商品名称",
                icon: "ios-search",
                clearable: "",
              },
              on: {
                "on-clear": function ($event) {
                  _vm.goodsData = []
                  _vm.goodsParams.title = ""
                  _vm.goodsParams.page = 1
                  _vm.getQueryGoodsList()
                },
                "on-enter": function () {
                  _vm.goodsData = []
                  _vm.goodsParams.page = 1
                  _vm.getQueryGoodsList()
                },
              },
              model: {
                value: _vm.goodsParams.title,
                callback: function ($$v) {
                  _vm.$set(_vm.goodsParams, "title", $$v)
                },
                expression: "goodsParams.title",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticStyle: { width: "400px" },
                attrs: {
                  clearable: "",
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "标签",
                },
                on: { change: _vm.changeTarget },
                model: {
                  value: _vm.checkTargets,
                  callback: function ($$v) {
                    _vm.checkTargets = $$v
                  },
                  expression: "checkTargets",
                },
              },
              _vm._l(_vm.targetOptions, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "query-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "ios-search" },
                on: {
                  click: function ($event) {
                    _vm.goodsData = []
                    _vm.getQueryGoodsList()
                  },
                },
              },
              [_vm._v("搜索")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { positon: "retavle" } }, [
        _c(
          "div",
          { staticClass: "flex f-j-e" },
          [
            _c("el-pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                small: "",
                layout: "prev, pager, next",
                total: _vm.total,
                "current-page": _vm.goodsParams.page,
                "page-size": _vm.goodsParams.pageSize,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.goodsParams, "page", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.goodsParams, "page", $event)
                },
                "update:pageSize": function ($event) {
                  return _vm.$set(_vm.goodsParams, "pageSize", $event)
                },
                "update:page-size": function ($event) {
                  return _vm.$set(_vm.goodsParams, "pageSize", $event)
                },
                "current-change": _vm.getQueryGoodsList,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "wap-content-list flex f-w",
            staticStyle: { height: "500px" },
          },
          [
            _vm._l(_vm.goodsData, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "wap-content-item f-s-0",
                  class: { active: item.selected },
                  on: {
                    click: function ($event) {
                      return _vm.checkedGoods(item, index)
                    },
                  },
                },
                [
                  _c("div", [
                    _c("img", { attrs: { src: item.mainImg, alt: "" } }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "wap-content-desc" }, [
                    _c("div", { staticClass: "wap-content-desc-title" }, [
                      item.type == 3
                        ? _c("span", { staticClass: "pintuan" }, [_vm._v("拼")])
                        : _vm._e(),
                      _vm._v(" "),
                      item.type == 2
                        ? _c("span", { staticClass: "miaosha" }, [_vm._v("秒")])
                        : _vm._e(),
                      _vm._v(
                        "\n              " +
                          _vm._s(item.title) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "wap-content-desc-bottom" }, [
                      _c("div", [_vm._v("$" + _vm._s(item.price))]),
                    ]),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            _vm.loading
              ? _c("div", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { size: "large", fix: "" },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.goodsData.length == 0
              ? _c(
                  "div",
                  { staticClass: "empty" },
                  [_c("el-empty", { attrs: { description: "暂无商品信息" } })],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }