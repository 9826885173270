exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "var(--primary)",
	"menuActiveText": "var(--primary)",
	"subMenuActiveText": "var(--color)",
	"menuBg": "var(--tiemeStyle)",
	"menuHover": "var(--primary)",
	"subMenuBg": "var(--tiemeStyle)",
	"subMenuHover": "var(--primary)",
	"sideBarWidth": "var(--counter)"
};