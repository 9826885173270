import defaultSettings from '@/settings';
var showSettings = defaultSettings.showSettings,
  tagsView = defaultSettings.tagsView,
  fixedHeader = defaultSettings.fixedHeader,
  sidebarLogo = defaultSettings.sidebarLogo,
  theme = defaultSettings.theme;
var state = {
  theme: theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  tieme_style: localStorage.getItem("tieme_style") ? localStorage.getItem("tieme_style") : 2,
  counter: localStorage.getItem("counter") ? localStorage.getItem("counter") : 242
  // tagsView: localStorage.getItem("tagsView"),
  // fixedHeader: localStorage.getItem("fixedHeader"),
  // sidebarLogo: localStorage.getItem("sidebarLogo"),
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    localStorage.setItem(key, value);
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};