export default [{
  title: "商品",
  url: "0",
  openGoods: true,
  name: "goods"
}, {
  title: "分类",
  url: "1",
  name: "category"
},
// {
//   title: "店铺",
//   url: "2",
//   name: "shops"
// },
/* {
  title: "活动",
  url: "3",
  name: "marketing"
}, */
{
  title: "页面",
  url: "2",
  name: "pages"
}, {
  title: "其他",
  url: "3",
  name: "other"
}];