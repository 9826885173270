import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getArticleList, getArticleCateSelector as _getArticleCateSelector } from '@/api/article';
import waves from '@/directive/waves'; // Waves directive
import { parseTime, renderTime } from '@/utils';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

var statusOptions = [{
  key: 1,
  text: '显示'
}, {
  key: 0,
  text: '草稿'
}];

// arr to obj ,such as { CN : "China", US : "USA" }
var statusKeyValue = statusOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.text;
  return acc;
}, {});
export default {
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {
    statusFilter: function statusFilter(status) {
      return statusKeyValue[status];
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20,
        type: 1
      },
      multipleSelection: [],
      statusOptions: statusOptions,
      cateOptions: [],
      activeName: 'first'
    };
  },
  created: function created() {
    this.getArticleCateSelector();
    this.getList();
  },
  methods: {
    callbackArticle: function callbackArticle(val) {
      this.list.forEach(function (item) {
        item.checked = false;
      });
      val.checked = true;
      val.___type = "pages";
      val.___path = "/pages/passport/article";
      this.$emit("selected", [val]);
    },
    getArticleCateSelector: function getArticleCateSelector() {
      var _this = this;
      _getArticleCateSelector().then(function (response) {
        _this.cateOptions = response.data;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      getArticleList(this.listQuery).then(function (response) {
        _this2.list = response.data.list;
        _this2.total = response.data.total;
        _this2.listLoading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};