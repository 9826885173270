var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _vm._l(_vm.linkList, function (item, index) {
            return (item.title !== "拼团频道" && item.title !== "签到") ||
              _vm.$route.name !== "renovation"
              ? _c("el-col", { key: index, attrs: { span: 4 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "card",
                      class: { active: _vm.selectedIndex == index },
                      on: {
                        click: function ($event) {
                          return _vm.handleLink(item, index)
                        },
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticStyle: { "font-size": "20px" },
                        attrs: { "icon-class": item.icon },
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(item.title))]),
                    ],
                    1
                  ),
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 4 } }, [
            _c(
              "div",
              {
                staticClass: "card",
                class: { active: _vm.selectedIndex == _vm.linkList.length },
                on: {
                  click: function ($event) {
                    return _vm.handleLink(_vm.linkItem, _vm.linkList.length)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticStyle: { "font-size": "20px" },
                  attrs: { "icon-class": _vm.linkItem.icon },
                }),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.linkItem.title))]),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }