/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var fundRouter = {
  path: '/fund',
  component: Layout,
  redirect: 'noredirect',
  name: 'fund',
  meta: {
    title: '财务管理',
    icon: 'money',
    roles: ['finance']
  },
  children: [{
    path: 'bank',
    component: function component() {
      return import('@/views/member/bank/index');
    },
    name: 'bank',
    meta: {
      title: '提现账户',
      icon: 'list',
      roles: ['finance:account']
    }
  }, {
    path: 'withdraw',
    component: function component() {
      return import('@/views/fund/withdraw/index');
    },
    name: 'withdraw',
    meta: {
      title: '商家佣金提现',
      icon: 'list',
      roles: ['finance:withdraw']
    }
  }, {
    path: 'moneywithdraw',
    component: function component() {
      return import('@/views/fund/moneywithdraw/index');
    },
    name: 'Moneywithdraw',
    meta: {
      title: '商家货款提现',
      icon: 'list',
      roles: ['finance:moneywithdraw']
    }
  },
  // {
  //   path: 'recharge',
  //   component: () => import('@/views/fund/recharge/index'),
  //   name: 'recharge',
  //   meta: { title: '充值记录', icon:'list', role: ['admin'] }
  // },
  {
    path: 'account',
    component: function component() {
      return import('@/views/fund/account/index');
    },
    name: 'account',
    meta: {
      title: '发货对账单',
      icon: 'zangdan',
      roles: ['finance:accountLog']
    }
  }, {
    path: 'supplierbill',
    component: function component() {
      return import('@/views/fund/account/supplierbill');
    },
    name: 'Supplierbill',
    meta: {
      title: '供应商发货对账单',
      icon: 'zangdan',
      roles: ['finance:supplierbill']
    }
  }, {
    path: 'mission',
    component: function component() {
      return import('@/views/fund/mission/index');
    },
    name: 'mission',
    meta: {
      title: '佣金对账单',
      icon: 'list',
      roles: ['finance:mission']
    }
  }, {
    path: 'suppliermission',
    component: function component() {
      return import('@/views/fund/suppliermission/index');
    },
    name: 'suppliermission',
    meta: {
      title: '供应商佣金对账单',
      icon: 'list',
      roles: ['finance:accountMissionLog']
    }
  }, {
    path: 'wechatpayment',
    component: function component() {
      return import('@/views/fund/wechatpayment/index');
    },
    name: 'Wechatpayment',
    meta: {
      title: '微信支付配置',
      icon: 'list',
      roles: ['finance:wechatpayment']
    }
  }]
};
export default fundRouter;