/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var shopRouter = {
  path: '/shop',
  component: Layout,
  name: 'shop',
  alwaysShow: true,
  meta: {
    title: '店铺管理',
    icon: 'shop-list',
    roles: ['shop']
  },
  children: [{
    path: 'info',
    component: function component() {
      return import('@/views/shop/info/index');
    },
    name: 'shopInfo',
    meta: {
      title: '店铺资料',
      icon: 'list',
      roles: ['shop:info']
    }
  }, {
    path: 'hubStudio',
    component: function component() {
      return import('@/views/shop/hubStudio/index');
    },
    name: 'hubStudioConfig',
    meta: {
      title: 'HubStudio配置',
      icon: 'list',
      roles: []
    }
  }]
};
export default shopRouter;