import Layout from '@/layout';
var smallRouter = {
  path: '/small',
  component: Layout,
  redirect: '/small/list',
  // redirect: 'noredirect',
  name: 'small',
  alwaysShow: true,
  meta: {
    title: '小店管理',
    // icon: 'report-brand'
    icon: 'guanli',
    roles: ['small']
  },
  children: [{
    path: 'order',
    component: function component() {
      return import('@/views/small/index/index');
    },
    name: 'smallOrder',
    meta: {
      title: '小店订单',
      icon: 'xiaodiandingdan'
    }
  }, {
    path: 'goods',
    component: function component() {
      return import('@/views/small/goods/index');
    },
    name: 'Goods',
    meta: {
      title: '批量发货',
      icon: 'shangpingguanli'
    },
    hidden: true
  }, {
    path: 'type',
    component: function component() {
      return import('@/views/small/product/index');
    },
    name: 'smallProduct',
    meta: {
      title: '我的货盘',
      icon: 'shangpingguanli'
    }
  }, {
    path: 'count',
    component: function component() {
      return import('@/views/small/count/index');
    },
    name: 'smallCount',
    meta: {
      title: '库存统计',
      icon: 'xiaodiantongji'
    }
  }, {
    path: 'push',
    component: function component() {
      return import('@/views/small/push/index');
    },
    name: 'product',
    meta: {
      title: '萤火货盘',
      icon: 'huopianguanli'
    }
  }, {
    path: 'applyorder',
    component: function component() {
      return import('@/views/small/push/applyorder');
    },
    name: 'Applyorder',
    meta: {
      title: '申请结果',
      icon: 'shenpi',
      roles: ['small:applyorder']
    }
  }, {
    path: '/profit',
    component: function component() {
      return import('@/views/small/index/profit.vue');
    },
    //index metalsindex
    name: 'profit',
    meta: {
      title: '利润试算',
      icon: 'kongzhitai',
      roles: ['small:profit'],
      noCache: true,
      affix: true,
      adminNoSee: true
    }
  }
  // {
  //   path: 'skuorder',
  //   component: () => import('@/views/small/push/skuorder'),
  //   name: 'Skuorder',
  //   meta: { title: '自传订单', icon: 'shenpi', roles: ['small:Skuorder'] }
  // },
  // {
  //   path: 'invoice',
  //   component: () => import('@/views/small/invoice/index'),
  //   name: 'Invoice',
  //   meta: { title: '手动发货台', icon: 'fahuotai', role: [] }
  // },
  ]
};
export default smallRouter;