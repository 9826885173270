import "core-js/modules/es6.array.find";
var CodeTableManager = {
  // 内置码表数据
  codeTables: {
    LOGISTICS_TRACKS: [{
      code: "NotFound",
      label: '查询不到',
      des: "查询不到，进行查询操作但没有得到结果，原因请参看子状态。"
    }, {
      code: "InfoReceived",
      label: '收到信息',
      des: "收到信息，运输商收到下单信息，等待上门取件。"
    }, {
      code: "InTransit",
      label: '运输途中',
      des: "运输途中，包裹正在运输途中，具体情况请参看子状态。"
    }, {
      code: "Expired",
      label: '运输过久',
      des: "运输过久，包裹已经运输了很长时间而仍未投递成功。"
    }, {
      code: "AvailabelForPickup",
      label: '到达待取',
      des: "到达待取，包裹已经到达目的地的投递点，需要收件人自取。"
    }, {
      code: "OutForDelivery",
      label: '派送途中',
      des: "派送途中，包裹正在投递过程中。"
    }, {
      code: "DeliveryFailure",
      label: '投递失败',
      des: "投递失败，包裹尝试派送但未能成功交付，原因请参看子状态。原因可能是:派送时收件人不在家、投递延误重新安排派送、收件人要求延迟派送、地址不洋无法派送、因信远地区不提供派送服务等。"
    }, {
      code: "Delivered",
      label: '成功签收',
      des: "成功签收，包裹已妥投。"
    }, {
      code: "Exception",
      label: '可能异常',
      des: "可能异常，包裹可能被退回，原因请参看子状态，原因可能是:收件人地址错误或不详、收件人拒收、包裹无人认领超过保留期等，包裹可能被海关扣留，常见扣关原因是:包含敏感违禁限制进出口的物品、未交税款等。包裹可能在运输途中遭受损坏、丢失、延误投递等特殊情况。"
    }]
  },
  // 获取整个数据集
  getDataSet: function getDataSet(tableName) {
    if (!tableName) {
      return null;
    }
    if (!this.codeTables[tableName]) {
      return null;
    }
    return this.codeTables[tableName];
  },
  getCode: function getCode(tableName, val, filed) {
    if (!tableName) {
      return null;
    }
    if (!this.codeTables[tableName]) {
      return null;
    }
    if (!filed) {
      filed = "des";
    }
    var item = this.codeTables[tableName].find(function (x) {
      return x[filed] == val;
    });
    if (!item) {
      return "";
    }
    return item.code;
  },
  getDes: function getDes(tableName, code, filed) {
    if (!tableName) {
      return null;
    }
    if (!this.codeTables[tableName]) {
      return null;
    }
    if (!filed) {
      filed = "des";
    }
    var item = this.codeTables[tableName].find(function (x) {
      return x.code == code;
    });
    if (!item) {
      return "";
    }
    return item[filed];
  }
};
export default CodeTableManager;