var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "service-time" },
    _vm._l(_vm.dates, function (item, index) {
      return _c(
        "el-row",
        { key: index },
        [
          _c("el-col", { attrs: { span: 10, align: "end" } }, [
            _vm._v(_vm._s(item.name) + "："),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 14 } }, [_vm._v(_vm._s(item.val))]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }