import _createForOfIteratorHelper from "D:/ideaProject/tk-shop/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "D:/ideaProject/tk-shop/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.replace";
import _objectSpread from "D:/ideaProject/tk-shop/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollPane from "./ScrollPane";
import path from "path";
import { mapState } from "vuex";
import { generateTitle } from "@/utils/i18n";
export default {
  components: {
    ScrollPane: ScrollPane
  },
  data: function data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
      color: "",
      title: this.$store.state.user.name.substr(0, 2)
    };
  },
  computed: _objectSpread(_objectSpread({
    visitedViews: function visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    }
  }, mapState({
    theme_color: function theme_color(state) {
      return state.settings.theme;
    }
  })), {}, {
    routes: function routes() {
      return this.$store.state.permission.routes;
    }
  }),
  watch: {
    $route: function $route() {
      this.addTags();
      this.moveToCurrentTag();
    },
    visible: function visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    }
  },
  mounted: function mounted() {
    this.initTags();
    this.addTags();
    console.log("缓存数据11212121212", JSON.parse(localStorage.getItem("theme")));
  },
  created: function created() {
    this.theme();
  },
  methods: {
    generateTitle: generateTitle,
    theme: function theme() {
      var color = this.darken(this.theme_color, 0.5);
      this.color = color;
      console.log("获取的颜色111111", this.theme_color, color);
    },
    //  使颜色变暗
    darken: function darken(color, level) {
      var rgb = this.hex2Rgb(color);
      for (var i = 0; i < 3; i++) {
        rgb[i] = Math.floor(rgb[i] * (1 - level));
      }
      var result = this.rgb2Hex(rgb[0], rgb[1], rgb[2]);
      return result;
    },
    hex2Rgb: function hex2Rgb(color) {
      color = color.replace("#", "");
      var result = color.match(/../g);
      for (var i = 0; i < 3; i++) {
        result[i] = parseInt(result[i], 16);
      }
      return result;
    },
    rgb2Hex: function rgb2Hex(r, g, b) {
      var hexs = [r.toString(16), g.toString(16), b.toString(16)];
      for (var i = 0; i < 3; i++) {
        if (hexs[i].length === 1) {
          hexs[i] = "0" + hexs[i];
        }
      }
      var result = "#" + hexs.join("");
      return result;
    },
    isActive: function isActive(route) {
      return route.path === this.$route.path;
    },
    isAffix: function isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    filterAffixTags: function filterAffixTags(routes) {
      var _this = this;
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "/";
      var tags = [];
      routes.forEach(function (route) {
        if (route.meta && route.meta.affix) {
          var tagPath = path.resolve(basePath, route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: _objectSpread({}, route.meta)
          });
        }
        if (route.children) {
          var tempTags = _this.filterAffixTags(route.children, route.path);
          if (tempTags.length >= 1) {
            tags = [].concat(_toConsumableArray(tags), _toConsumableArray(tempTags));
          }
        }
      });
      return tags;
    },
    initTags: function initTags() {
      var affixTags = this.affixTags = this.filterAffixTags(this.routes);
      var _iterator = _createForOfIteratorHelper(affixTags),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var tag = _step.value;
          // Must have tag name
          if (tag.name) {
            this.$store.dispatch("tagsView/addVisitedView", tag);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    addTags: function addTags() {
      var name = this.$route.name;
      if (name) {
        this.$store.dispatch("tagsView/addView", this.$route);
      }
      return false;
    },
    moveToCurrentTag: function moveToCurrentTag() {
      var _this2 = this;
      var tags = this.$refs.tag;
      this.$nextTick(function () {
        var _iterator2 = _createForOfIteratorHelper(tags),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var tag = _step2.value;
            if (tag.to.path === _this2.$route.path) {
              _this2.$refs.scrollPane.moveToTarget(tag);
              // when query is different then update
              if (tag.to.fullPath !== _this2.$route.fullPath) {
                _this2.$store.dispatch("tagsView/updateVisitedView", _this2.$route);
              }
              break;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      });
    },
    refreshSelectedTag: function refreshSelectedTag(view) {
      var _this3 = this;
      this.$store.dispatch("tagsView/delCachedView", view).then(function () {
        var fullPath = view.fullPath;
        _this3.$nextTick(function () {
          _this3.$router.replace({
            path: "/redirect" + fullPath
          });
        });
      });
    },
    closeSelectedTag: function closeSelectedTag(view) {
      var _this4 = this;
      this.$store.dispatch("tagsView/delView", view).then(function (_ref) {
        var visitedViews = _ref.visitedViews;
        if (_this4.isActive(view)) {
          _this4.toLastView(visitedViews, view);
        }
      });
    },
    closeOthersTags: function closeOthersTags() {
      var _this5 = this;
      this.$router.push(this.selectedTag);
      this.$store.dispatch("tagsView/delOthersViews", this.selectedTag).then(function () {
        _this5.moveToCurrentTag();
      });
    },
    closeAllTags: function closeAllTags(view) {
      var _this6 = this;
      this.$store.dispatch("tagsView/delAllViews").then(function (_ref2) {
        var visitedViews = _ref2.visitedViews;
        if (_this6.affixTags.some(function (tag) {
          return tag.path === view.path;
        })) {
          return;
        }
        _this6.toLastView(visitedViews, view);
      });
    },
    toLastView: function toLastView(visitedViews, view) {
      var latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView.fullPath);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === "Dashboard") {
          // to reload home page
          this.$router.replace({
            path: "/redirect" + view.fullPath
          });
        } else {
          this.$router.push("/");
        }
      }
    },
    openMenu: function openMenu(tag, e) {
      var menuMinWidth = 105;
      var offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      var offsetWidth = this.$el.offsetWidth; // container width
      var maxLeft = offsetWidth - menuMinWidth; // left boundary
      var left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      this.top = e.clientY;
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    },
    handleScroll: function handleScroll() {
      this.closeMenu();
    }
  }
};