var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "navbar" }, [
        _c(
          "div",
          { staticClass: "navbar-left" },
          [
            _c("hamburger", {
              staticClass: "hamburger-container",
              attrs: { "is-active": _vm.sidebar.opened },
              on: { toggleClick: _vm.toggleSideBar },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "top_icon hamburger-container",
                on: { click: _vm.add_reload },
              },
              [_c("i", { staticClass: "el-icon-refresh-right" })]
            ),
            _vm._v(" "),
            _c("breadcrumb", { staticClass: "breadcrumb-container" }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.notice_number > 0
          ? _c(
              "div",
              { staticClass: "navbar-center", on: { click: _vm.add_notice } },
              [
                _c("div", { staticClass: "center-notification" }, [
                  _vm._v(
                    "\n       您有 " +
                      _vm._s(_vm.notice_number) +
                      " 条新消息提醒，请注意查看！\n      "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "navbar-right" }, [
          _c(
            "div",
            { staticClass: "right-menu" },
            [
              _c("ServiceTime", { staticStyle: { "margin-right": "20px" } }),
              _vm._v(" "),
              _c("screenfull", {
                staticClass: "right-menu-item hover-effect",
                attrs: { id: "screenfull" },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "notice_box right-menu-item hover-effect" },
                [
                  _vm.notice_number > 0
                    ? _c(
                        "el-badge",
                        {
                          staticClass: "item",
                          attrs: { value: _vm.notice_number },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.add_notice($event)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-bell",
                            staticStyle: {
                              "font-size": "16px",
                              cursor: "pointer",
                            },
                          }),
                        ]
                      )
                    : _c("i", {
                        staticClass: "el-icon-bell",
                        staticStyle: { "font-size": "16px", cursor: "pointer" },
                        on: { click: _vm.add_notice },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "head_left" }, [
                _c("img", { attrs: { src: _vm.head_img, alt: "" } }),
              ]),
              _vm._v(" "),
              _vm.rolesList && _vm.rolesList.length > 0
                ? _c(
                    "el-dropdown",
                    {
                      staticClass:
                        "avatar-container right-menu-item hover-effect",
                      attrs: { trigger: "click" },
                      on: { command: _vm.handleCommand },
                    },
                    [
                      _c("div", { staticClass: "avatar-wrapper" }, [
                        _c("div", { staticClass: "roleName_color" }, [
                          _vm._v(_vm._s(_vm.roleName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { padding: "0 5px" } }, [
                          _c("i", {
                            staticClass: "el-icon-arrow-down",
                            staticStyle: { "font-size": "15px", color: "#666" },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(_vm.rolesList, function (item, index) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: item.roleId,
                              attrs: {
                                command: item.deptName + "-" + item.roleName,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.deptName + "-" + item.roleName) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container right-menu-item hover-effect",
                  attrs: { trigger: "click" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "avatar-wrapper flex f-a-c f-j-s" },
                    [
                      _c("div", { staticClass: "margin-l6 realname_color" }, [
                        _vm._v(_vm._s(_vm.userData.realname)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { padding: "0 5px" } }, [
                        _c("i", {
                          staticClass: "el-icon-arrow-down",
                          staticStyle: { "font-size": "15px", color: "#666" },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/" } },
                        [
                          _c("el-dropdown-item", [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("navbar.dashboard")) +
                                "\n              "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.add_AccountInfo($event)
                            },
                          },
                        },
                        [_vm._v("\n              个人中心\n            ")]
                      ),
                      _vm._v(" "),
                      _vm.mainFlag
                        ? _c(
                            "el-dropdown-item",
                            {
                              attrs: { divided: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.add_invite($event)
                                },
                              },
                            },
                            [_vm._v("\n              邀请注册\n            ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.userData.accountType == 1 && !_vm.vip_sw
                        ? _c(
                            "el-dropdown-item",
                            {
                              attrs: { divided: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.vipisVip_url($event)
                                },
                              },
                            },
                            [_vm._v("\n              开通VIP\n            ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.add_logon($event)
                            },
                          },
                        },
                        [_vm._v("\n              切换登录\n            ")]
                      ),
                      _vm._v(" "),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: { click: _vm.logout },
                          },
                          [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "hamburger-container",
                  staticStyle: { padding: "0px 7px 0px 7px" },
                  on: { click: _vm.add_drawer },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-setting",
                    staticStyle: { "font-size": "17px", color: "#666" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-drawer",
                {
                  attrs: {
                    title: "主题设置",
                    visible: _vm.drawer,
                    direction: _vm.direction,
                    "append-to-body": true,
                    size: "250px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.drawer = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form_box" },
                    [
                      _c("div", { staticClass: "item_box" }, [
                        _c("span", { staticClass: "item_text" }, [
                          _vm._v("风格设置"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "img_box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "img_son",
                              on: {
                                click: function ($event) {
                                  return _vm.add_theme(1)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/heise.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _vm.theme_sw == 1
                                ? _c("div", { staticClass: "icon_img" }, [
                                    _c("i", { staticClass: "el-icon-check" }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "img_son",
                              on: {
                                click: function ($event) {
                                  return _vm.add_theme(2)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/baise.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _vm.theme_sw == 2
                                ? _c("div", { staticClass: "icon_img" }, [
                                    _c("i", { staticClass: "el-icon-check" }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("Settings"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item_box_df" },
                        [
                          _c(
                            "el-button",
                            { on: { click: _vm.add_resetting_theme } },
                            [_vm._v("重置主题")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "切换登录",
            visible: _vm.dialog_sw,
            width: "30%",
            "append-to-body": true,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog_sw = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list, border: "", fit: "" },
              on: { "row-click": _vm.add_table },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "username", label: "登录账号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                scope.row.realname == _vm.userData.realname
                                  ? "#409eff"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(scope.row.username))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "realname", label: "账号名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                scope.row.realname == _vm.userData.realname
                                  ? "#409eff"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(scope.row.realname))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.detailDialogVisible,
            title: "通知",
            width: "60%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                size: "mini",
                "label-width": "90px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "0px" },
                      attrs: { size: "mini", type: "danger" },
                      on: { click: _vm.add_getTypeList },
                    },
                    [_vm._v("删除\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "orderTable",
              attrs: { data: _vm.notice_list, border: "", "max-height": 500 },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "45" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "通知标题",
                  prop: "title",
                  align: "center",
                  width: "250",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "通知内容", prop: "content", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "通知时间",
                  prop: "add_time",
                  align: "center",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("parseTime")(
                                scope.row.addTime,
                                "{y}-{m}-{d} {h}:{i}:{s}"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.notice_total > 0,
                expression: "notice_total > 0",
              },
            ],
            staticStyle: { width: "90%" },
            attrs: {
              total: _vm.notice_total,
              page: _vm.notice_obj.page,
              limit: _vm.notice_obj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.notice_obj, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.notice_obj, "pageSize", $event)
              },
              pagination: _vm.deleteVip_url,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Shopvpi", {
        attrs: { detailDialogVisible: _vm.detailDialogVisible_vip },
        on: { close: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }