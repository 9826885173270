var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        "--color": _vm.defaultTheme,
        "--themeColor": _vm.color,
        "--tiemeStyle": _vm.tieme_style_color,
        "--fontStyle": _vm.font_style_color,
      },
      attrs: { id: "app" },
    },
    [
      _c("Settings", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        on: { change: _vm.themeChange },
      }),
      _vm._v(" "),
      _vm.isRouterAlive ? _c("router-view") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }