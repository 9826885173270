//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QRCode from "qrcode";
import { vipisVip, vipgetList, vipgetVipInfo, getVipTypeList } from "@/api/vip";
export default {
  props: ["detailDialogVisible"],
  data: function data() {
    return {
      vip_sw: false,
      // detailDialogVisible: false,
      card_list: [],
      card_index: 0,
      img: require("./img/vip.png"),
      QRImgUrl: "",
      innerVisible: false,
      setTimeout: "",
      dialogWidth: "0px"
    };
  },
  mounted: function mounted() {},
  methods: {
    // 点击关闭
    add_close: function add_close() {
      console.log("发送了吗");
      this.detailDialogVisible = false;
      this.$emit("close", false);
    },
    add_getVipTypeList: function add_getVipTypeList() {
      var _this = this;
      var vipConfigId = this.card_list[this.card_index].id;
      console.log("数据", vipConfigId);
      // 获取支付信息
      getVipTypeList(vipConfigId).then(function (res) {
        var message = JSON.parse(res.data.message);
        QRCode.toDataURL(message.codeurl, {
          errorCorrectionLevel: "L",
          margin: 2,
          width: 250
        }, function (err, url) {
          if (err) throw err;
          _this.QRImgUrl = url;
          _this.innerVisible = true;
          _this.setTimeoutvipisVip_url();
        });
        console.log("支付信息", res, message);
      });
    },
    // 获取是否会员
    setTimeoutvipisVip_url: function setTimeoutvipisVip_url() {
      var _this2 = this;
      vipisVip().then(function (res) {
        if (!res.data) {
          _this2.setTimeout = setTimeout(function () {
            _this2.setTimeoutvipisVip_url();
          }, 1000);
        } else {
          _this2.$message({
            message: "恭喜你，支付成功！",
            type: "success"
          });
          _this2.detailDialogVisible = false;
          _this2.innerVisible = false;
          _this2.$emit("close", false);
        }
        _this2.vip_sw = res.data;
        console.log("是否vip", res);
      });
    },
    add_son_close: function add_son_close() {
      console.log("清除");
      clearTimeout(this.setTimeout);
    },
    // 获取支付信息
    getVipTypeList_url: function getVipTypeList_url() {},
    // 点击会员
    add_card: function add_card(item, index) {
      this.card_index = index;
    },
    // 获取VIP信息
    vipgetVipInfo_url: function vipgetVipInfo_url() {
      vipgetVipInfo().then(function (res) {
        console.log("VIP信息", res);
      });
    },
    // 获取VIP价
    vipgetList_url: function vipgetList_url() {
      var _this3 = this;
      vipgetList().then(function (res) {
        _this3.card_list = res.data;
        _this3.dialogWidth = _this3.card_list.length * 230 + 26 + "px";
        // let obj = [
        //   {
        //     title: "hhhh",
        //     price: "188",
        //     content: "免费观看vip视频"
        //   },
        //   {
        //     title: "hhhh",
        //     price: "188",
        //     content: "免费观看vip视频"
        //   },
        //   {
        //     title: "hhhh",
        //     price: "188",
        //     content: "免费观看vip视频"
        //   },
        //   {
        //     title: "hhhh",
        //     price: "188",
        //     content: "免费观看vip视频"
        //   }
        // ];
        // this.card_list = obj;
        console.log("VIP价", res);
      });
    },
    //判断用户是否vip
    vipisVip_url: function vipisVip_url() {
      var _this4 = this;
      vipisVip().then(function (res) {
        _this4.vip_sw = res.data;
        console.log("是否vip", res);
      });
    }
  },
  created: function created() {
    this.vipisVip_url();
    this.vipgetList_url();
    this.vipgetVipInfo_url();
  }
};