import Layout from '@/layout';
var accountRouter = {
  path: '/freightcount',
  component: Layout,
  children: [{
    path: 'freightcount',
    name: 'Freightcount',
    component: function component() {
      return import('@/views/freightcount/freight/index');
    },
    meta: {
      title: '运费估算',
      icon: 'zangdan',
      roles: ['freight:freightcount'],
      adminNoSee: true
    }
  }]
};
export default accountRouter;