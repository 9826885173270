import category from "./category.vue";
import shops from "./shops.vue";
import marketing from "./marketing.vue";
import pages from "./pages.vue";
import goods from "../goods-dialog.vue";
import other from "./other.vue";
export default {
  pages: pages,
  marketing: marketing,
  shops: shops,
  category: category,
  goods: goods,
  other: other
};