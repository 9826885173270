import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
import _objectSpread from "D:/ideaProject/tk-shop/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
export default {
  name: "lemonMessageOrder",
  inheritAttrs: false,
  inject: ["IMUI"],
  render: function render() {
    var h = arguments[0];
    return h("lemon-message-basic", _mergeJSXProps([{
      "class": "lemon-message-order"
    }, {
      "props": _objectSpread({}, this.$attrs)
    }, {
      "scopedSlots": {
        content: function content(props) {
          var msg = JSON.parse(props.content);
          return h("div", {
            "class": "f-c f-a-s  bg-color-w"
          }, [h("div", {
            "class": "flex f-a-c  w100 f11-size t-color-3 margin-t6 f-w-b"
          }, ["\u60A8\u6B63\u5728\u54A8\u8BE2\u7684\u8BA2\u5355"]), h("div", {
            "class": "flex margin-t6"
          }, [h("img", {
            "class": "flex f-s-0 w-70 h-70 bg-img margin-r10",
            "attrs": {
              "src": msg.mainImg
            }
          }), h("div", {
            "class": "flex flex-1 f-c f-j-s h100"
          }, [h("div", {
            "class": "w-200"
          }, [h("div", {
            "class": "line2 f12-size t-color-6"
          }, [msg.title])]), h("div", {
            "class": "flex f-a-c margin-t6 f12-size t-color-9"
          }, [h("div", {
            "class": "margin-r4"
          }, ["\u5408\u8BA1"]), h("div", {
            "class": "text-price"
          }, [msg.price, ".00"])]), h("div", {
            "class": "flex f-a-c  w100 f12-size t-color-9 margin-t2"
          }, ["\u8BA2\u5355\u53F7: ", msg.orderNo])])])]);
        }
      }
    }]));
  }
};