//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThemePicker from "@/components/ThemePicker";
export default {
  components: {
    ThemePicker: ThemePicker
  },
  data: function data() {
    return {};
  },
  computed: {
    counter: {
      get: function get() {
        return this.$store.state.settings.counter;
      },
      set: function set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "counter",
          value: val
        });
      }
    },
    fixedHeader: {
      get: function get() {
        return this.$store.state.settings.fixedHeader;
      },
      set: function set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "fixedHeader",
          value: val
        });
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.settings.tagsView;
      },
      set: function set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "tagsView",
          value: val
        });
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "sidebarLogo",
          value: val
        });
      }
    }
  },
  mounted: function mounted() {
    if (localStorage.getItem("theme")) {
      console.log("themethemethemethemetheme", localStorage.getItem("theme"));
      this.themeChange(localStorage.getItem("theme"));
    }
  },
  methods: {
    themeChange: function themeChange(val) {
      console.log("触发了吗", val);
      // localStorage.setItem("theme", val);
      this.$store.dispatch("settings/changeSetting", {
        key: "theme",
        value: val
      });
    }
  }
};