var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        {
          staticClass: "scrollbar_style",
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c(
            "div",
            { staticClass: "head_box", style: { backgroundColor: _vm.menuBg } },
            [
              _c("div", { staticClass: "head_left" }, [
                _c("img", { attrs: { src: _vm.head_img, alt: "" } }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "head_right" }, [
                _c(
                  "div",
                  { staticClass: "head_title", style: { color: _vm.menuText } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.deptName) +
                        "-" +
                        _vm._s(_vm.roleName) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "head_username",
                    style: { color: _vm.menuText },
                  },
                  [_vm._v("\n          " + _vm._s(_vm.username) + "\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "head_login", on: { click: _vm.add_list } },
                  [
                    _c("div", { staticClass: "head_image" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/views/org/img/qiehuan.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "head_zi",
                        style: { color: _vm.menuText },
                      },
                      [_vm._v("\n            切换账号\n          ")]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.menuBg,
                "text-color": _vm.menuText,
                "collapse-transition": false,
                "unique-opened": true,
                mode: "vertical",
              },
            },
            _vm._l(_vm.permission_routes, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.list_sw
        ? _c(
            "div",
            {
              staticClass: "data_box",
              style: {
                left: _vm.sidebar.opened
                  ? " var(--counter) !important"
                  : "54px !important",
              },
            },
            [
              _c(
                "div",
                { staticClass: "list_box" },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "list_for",
                      on: {
                        click: function ($event) {
                          return _vm.add_table(item)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "list_son",
                          style: {
                            borderBottom:
                              index == _vm.list.length - 1
                                ? "0px"
                                : "1px solid #546382",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "list_username",
                              style: {
                                color:
                                  item.realname == _vm.userData.realname
                                    ? "#409eff"
                                    : "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.username) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              style: {
                                color:
                                  item.realname == _vm.userData.realname
                                    ? "#409eff"
                                    : "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.realname) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }