import request from '@/utils/requestJson';
//获取VIP价
export function vipgetList() {
  return request({
    url: '/vip/getList',
    method: 'get'
  });
}
//用户是否VIP
export function vipisVip() {
  return request({
    url: '/vip/isVip',
    method: 'get'
  });
}
//获取VIP信息
export function vipgetVipInfo() {
  return request({
    url: '/vip/getVipInfo',
    method: 'get'
  });
}
//VIP创建订单
export function getVipTypeList(vipConfigId) {
  return request({
    url: '/vip/createOrder?payMethod=wxPay&applicationType=app&vipConfigId=' + vipConfigId,
    method: 'post'
  });
}
//未读数量
export function getUnReadNum() {
  return request({
    url: '/shop/notify/getUnReadNum',
    method: 'get'
  });
}
//未读消息设置成已读
export function read(ids) {
  return request({
    url: '/shop/notify/read?ids=' + ids,
    method: 'post'
  });
}
// 分页查看通知
export function deleteVip(data) {
  return request({
    url: "/shop/notify/getPage",
    method: 'get',
    params: data
  });
}
//删除消息
export function getTypeList(ids) {
  return request({
    url: '/shop/notify/deleteByIds?ids=' + ids,
    method: 'post'
  });
}