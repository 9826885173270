import "core-js/modules/es6.function.name";
import _objectSpread from "D:/ideaProject/tk-shop/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { asyncRoutes, constantRoutes } from '@/router';

/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
export function filterAdminNoseeRoutes(routes, shopInfo) {
  var res = [];
  console.log("shopInfo+++++++++++++++++++++++++++++", shopInfo);
  var username = shopInfo.memberName;
  routes.forEach(function (route) {
    if (route.children) {
      route.children = filterAdminNoseeRoutes(route.children, shopInfo);
    }
    if (!(route.meta && route.meta.adminNoSee)) {
      //控制admin不看的路由
      //添加GLT特例不看的路由
      var GLTNoSee = ['smallCount', 'bank', 'withdraw', 'Moneywithdraw', 'mission', 'account', 'suppliermission', 'Wechatpayment'];
      if (!(username == 'GLT' && GLTNoSee.includes(route.name))) {
        res.push(route);
      }
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, _ref2) {
    var commit = _ref.commit;
    var roles = _ref2.roles,
      shopInfo = _ref2.shopInfo;
    return new Promise(function (resolve) {
      var accessedRoutes;
      if (roles.includes('admin')) {
        accessedRoutes = filterAdminNoseeRoutes(asyncRoutes, shopInfo); //过滤管理员不让看的
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      }
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};