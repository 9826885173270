import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import EmojiData from "./database/emoji";
import { getChatSessionList, deleteChatSession, getChatList, sendMsg as _sendMsg, readBySession, countUnReadNum as _countUnReadNum } from "@/api/chat";
import { upload } from "@/api/system";
import axios from "axios";
import Vue from "vue";
import LemonMessageGoods from "./lemon-message-goods";
import LemonMessageOrder from "./lemon-message-order";
import LemonMessageNotice from "./lemon-message-notice";
Vue.component(LemonMessageGoods.name, LemonMessageGoods);
Vue.component(LemonMessageOrder.name, LemonMessageOrder);
Vue.component(LemonMessageNotice.name, LemonMessageNotice);
import Notice from "@/layout/components/Notice";
var IMUI;
export default {
  directives: {
    elDragDialog: elDragDialog
  },
  components: {
    Notice: Notice
  },
  data: function data() {
    return {
      imDialog: false,
      contactContextmenu: [{
        text: "删除聊天",
        click: function click(e, instance, hide) {
          var IMUI = instance.IMUI,
            contact = instance.contact;
          IMUI.updateContact({
            id: contact.id,
            lastContent: null
          });
          if (IMUI.currentContactId == contact.id) IMUI.changeContact(null);
          hide();
          deleteChatSession({
            sessionId: contact.sessionId
          }).then(function (response) {});
        }
      }],
      contextmenu: [{
        click: function click(e, instance, hide) {
          var message = instance.message;
          window.open(message.content);
          hide();
        },
        visible: function visible(instance) {
          return instance.message.type == "image";
        },
        text: "查看大图"
      }],
      chatSessionList: null,
      chatList: [],
      user: {
        id: this.$store.state.user.id,
        displayName: this.$store.state.user.name,
        avatar: this.$store.state.user.avatar
      },
      page: 1,
      totalUnReadNum: 0
    };
  },
  watch: {
    totalUnReadNum: function totalUnReadNum(newValue, oldValue) {
      if (newValue > 0) {
        this.$refs.noticeFlash.tip("您有新的消息, 请及时查看!", "新消息");
      } else {
        this.$refs.noticeFlash.doClearTimer();
      }
    }
  },
  methods: {
    handleIm: function handleIm(memberId) {
      var _this = this;
      this.totalUnReadNum = 0;
      this.imDialog = true;
      this.$nextTick(function () {
        _this.getContect(memberId);
      });
    },
    getContect: function getContect(memberId) {
      var _this2 = this;
      if (!IMUI) {
        IMUI = this.$refs.IMUI;
      }
      IMUI.initEditorTools([{
        name: "emoji"
      }, {
        name: "uploadImage"
      }]);
      //初始化表情包。
      IMUI.initEmoji(EmojiData);
      //从后端请求联系人数据，包装成下面的样子
      var contacts = [];
      getChatSessionList({
        targetMemberId: memberId,
        page: 1,
        pageSize: 9999
      }).then(function (response) {
        _this2.chatSessionList = response.data.list;
        if (_this2.chatSessionList && _this2.chatSessionList.length > 0) {
          _this2.chatSessionList.forEach(function (item) {
            if (!item.lastMsg) {
              item.lastMsg = "   ";
            }
            var lastMsg = "";
            if (item.msgType == "text") {
              lastMsg = item.lastMsg;
            } else if (item.msgType == "image") {
              lastMsg = "[图片]";
            } else if (item.msgType == "file") {
              lastMsg = "[文件]";
            } else if (item.msgType == "goods") {
              lastMsg = "[咨询商品]";
            } else if (item.msgType == "order") {
              lastMsg = "[咨询订单]";
            } else if (item.msgType == "notice") {
              lastMsg = "[您有一条重要的待办待处理]";
            }
            contacts.push({
              sessionId: item.id,
              id: item.targetId,
              displayName: item.targetNickname,
              avatar: item.targetHeadImg,
              unread: item.unReadNum,
              //最近一条消息的内容，如果值为空，不会出现在“聊天”列表里面。
              //lastContentRender 函数会将 file 消息转换为 '[文件]', image 消息转换为 '[图片]'，对 text 会将文字里的表情标识替换为img标签,
              lastContent: lastMsg,
              //最近一条消息的发送时间
              lastSendTime: item.updTime
            });
          });
          IMUI.initContacts(contacts);
          if (contacts.length > 0) {
            setTimeout(function () {
              if (memberId) {
                IMUI.changeContact(memberId);
              } else {
                IMUI.changeContact(contacts[0].id);
              }
            }, 500);
          }
        }
      });
    },
    //加载聊天记录
    handlePullMessages: function handlePullMessages(contact, next, instance) {
      var _this3 = this;
      var messages = [];
      var total = 0;
      getChatList({
        targetMemberId: contact.id,
        page: this.page,
        pageSize: 50
      }).then(function (response) {
        _this3.chatList = response.data.list;
        total = response.data.total;
        if (_this3.page <= response.data.totalPage) {
          _this3.page++;
        }
        if (_this3.chatList && _this3.chatList.length > 0) {
          _this3.chatList.forEach(function (item) {
            messages.push({
              id: item.id,
              status: "succeed",
              type: item.msgType,
              sendTime: item.sendTime,
              content: item.msgContent,
              toContactId: item.sendType == 1 ? item.targetId : item.memberId,
              fromUser: {
                id: item.sendType == 1 ? item.memberId : item.targetId,
                displayName: item.sendType == 1 ? item.memberNickname : item.targetNickname,
                avatar: item.sendType == 1 ? item.memberHeadImg : item.targetHeadImg
              }
            });
          });
        }
        setTimeout(function () {
          var isEnd = false;
          if (_this3.page - 1 >= response.data.totalPage) {
            isEnd = true;
          }
          next(messages, isEnd);
        }, 500);
      });
    },
    handleSend: function handleSend(message, next, file) {
      var self = this;
      var sendForm = {
        targetMemberId: message.toContactId,
        msgType: message.type,
        content: message.content
      };
      if (file) {
        //上传到服务器
        self.addImg({
          file: file,
          success: function success(res) {
            sendForm.content = res;
            self.sendMsg(sendForm, next);
          }
        });
      } else {
        self.sendMsg(sendForm, next);
      }
    },
    sendMsg: function sendMsg(sendForm, next) {
      _sendMsg(sendForm).then(function (response) {
        setTimeout(function () {
          next();
        }, 500);
      });
    },
    handleChangeContact: function handleChangeContact(contact, instance) {
      this.page = 1;
      instance.updateContact({
        id: contact.id,
        unread: 0
      });
      if (contact.sessionId) {
        readBySession({
          sessionId: contact.sessionId
        }).then(function (response) {});
      }
    },
    watchSocket: function watchSocket() {
      var self = this;
      self.socket.onMessage = function (res) {
        //将接收到的数据包装成下面的样子
        var info = JSON.parse(res);
        if (info.cmd == 5) {
          var data = JSON.parse(info.data);
          if (data.type && data.type == 5) {
            var item = JSON.parse(data.content);
            var msg = {
              id: item.id,
              status: "succeed",
              type: item.msgType,
              sendTime: item.sendTime,
              content: item.msgContent,
              toContactId: item.sendType == 2 ? item.targetId : item.memberId,
              fromUser: {
                id: item.sendType == 1 ? item.memberId : item.targetId,
                displayName: item.sendType == 1 ? item.memberNickname : item.targetNickname,
                avatar: item.sendType == 1 ? item.memberHeadImg : item.targetHeadImg
              }
            };
            self.playAudio();
            var msgContent;
            if (msg.type == "image") {
              msgContent = "[图片]";
            } else if (msg.type == "file") {
              msgContent = "[文件]";
            } else if (msg.type == "goods") {
              msgContent = "[咨询商品]";
            } else if (msg.type == "order") {
              msgContent = "[咨询订单]";
            } else if (msg.type == "notice") {
              msgContent = "[您有一条重要的待办待处理]";
            } else {
              msgContent = msg.content;
            }
            if (!IMUI || !self.imDialog) {
              //未打开聊天窗口
              self.totalUnReadNum++;
              self.$notify({
                title: "",
                dangerouslyUseHTMLString: true,
                // message: '<img style="width:50px;height:50px;border-radius:20%;" src="'+msg.fromUser.avatar+'"></img> :'+msg.content,
                message: '<div class="flex">' + '<img class="flex f-s-0 margin-r8 margin-t8" style="width:40px;height:40px;border-radius:50%;" ' + 'size="small"' + 'src="' + msg.fromUser.avatar + '"></img>' + '<div class="flex flex-1 f-c margin-t4">' + '<span style="font-size: 13px;font-weight: bold;">' + msg.fromUser.displayName + "</span>" + '<span style=" font-size: 13px;">' + msgContent + "</span>" + "</div>" + "</div>",
                position: "bottom-right",
                duration: 5000,
                offset: 100
              });
            }
            if (IMUI) {
              IMUI.appendMessage(msg, true);
              IMUI.setLastContentRender("text", function (msg) {
                return msgContent;
              });
            }
          }
        }
      };
    },
    handleMessageClick: function handleMessageClick(e, key, message, instance) {
      if (message.type == "notice") {
        var content = JSON.parse(message.content);
        if (content.type == 1) {
          //提现
          this.$router.push("/fund/withdraw");
        } else if (content.type == 2) {
          //新订单
          this.$router.push("/order/list");
        } else if (content.type == 3 || content.type == 4) {
          //退款
          this.$router.push("/order/refund");
        }
      }
    },
    playAudio: function playAudio() {
      var self = this;
      var audio = this.$refs.chataudio;
      var play = audio.play();
      if (play !== undefined) {
        play.then(function (res) {
          // Automatic playback started!
        }).catch(function (error) {
          self.$alert("有新的聊天信息,请及时查看", "消息提醒", {
            confirmButtonText: "确定",
            callback: function callback(action) {
              self.playAudio();
            }
          });
        });
      }
    },
    addImg: function addImg(options) {
      var self = this;
      var formdata = new FormData();
      formdata.append("file", options.file);
      var postData = {
        // 设置axios的参数
        url: process.env.VUE_APP_BASE_API + "common/upload",
        data: formdata,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      axios(postData).then(function (res) {
        if (options.success) {
          options.success(res.data.data.imgUrl);
        }
      });
    },
    countUnReadNum: function countUnReadNum() {
      var _this4 = this;
      _countUnReadNum().then(function (response) {
        _this4.totalUnReadNum = response.data;
      });
    }
  },
  created: function created() {
    var _this5 = this;
    this.countUnReadNum();
    this.$nextTick(function () {
      _this5.watchSocket();
    });
  },
  mounted: function mounted() {}
};