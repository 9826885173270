import Layout from '@/layout';
var logisticsRouter = {
  path: '/logistics',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Logistics',
    component: function component() {
      return import('@/views/logistics/index');
    },
    meta: {
      title: '物流查询',
      icon: 'fahuotai',
      roles: ['logistics'],
      adminNoSee: true
    }
  }]
};
export default logisticsRouter;