import Layout from '@/layout';
var sysmanageRouter = {
  path: '/sysmanage',
  component: Layout,
  redirect: '/sysmanage/index',
  name: 'sysmanage',
  alwaysShow: true,
  meta: {
    title: '平台管理',
    icon: 'report-brand',
    roles: ['sysmanage'],
    adminNoSee: true
  },
  children: [
  // {
  //   path: 'tikTokWarehouse',
  //     component: () => import('@/views/sysmanage/tikTok/index'),
  //   name: 'tikTokWarehouse',
  //   meta: { title: 'tikTok仓库配置', role: ['sysmanage:tikTokWarehouse'] }
  // },
  {
    path: 'tiktokSetting',
    component: function component() {
      return import('@/views/sysmanage/authConfig/index');
    },
    name: 'tiktokSetting',
    meta: {
      title: '授权管理',
      role: ['sysmanage:tiktokSetting']
    }
  }]
};
export default sysmanageRouter;