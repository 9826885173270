import Layout from '@/layout';
var logisticsRouter = {
  path: '/expertlibrary',
  name: 'expertlibrary',
  meta: {
    title: 'Tiktok',
    adminNoSee: true,
    icon: 'fahuotai',
    roles: ['expertlibrary']
  },
  component: Layout,
  children: [{
    path: 'expertlibrarylist',
    name: 'Expertlibrarylist',
    component: function component() {
      return import('@/views/expertlibrary/expertlibrarylist');
    },
    meta: {
      title: '达人库',
      icon: 'fahuotai',
      roles: ['expertlibrary:expertlibrarylist'],
      adminNoSee: true
    }
  }, {
    path: 'productSales',
    name: 'productSales',
    component: function component() {
      return import('@/views/expertlibrary/productSales');
    },
    meta: {
      title: '产品销量',
      icon: 'fahuotai',
      roles: ['expertlibrary:productSales'],
      adminNoSee: true
    }
  }, {
    path: 'expertlibrarydetails',
    name: 'Expertlibrarydetails',
    component: function component() {
      return import('@/views/expertlibrary/expertlibrarydetails');
    },
    meta: {
      title: '达人详情',
      icon: 'fahuotai',
      roles: []
    },
    hidden: true
  }]
};
export default logisticsRouter;