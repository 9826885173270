//
//
//
//
//

export default {
  name: "Notice",
  props: {
    sound: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      tipTimer: null,
      tipTimerCount: 0,
      titleOld: null
    };
  },
  methods: {
    tip: function tip(msg, type) {
      this.doPageTitle(msg, type);
      if (this.sound) {
        this.doPlaySound();
      }
    },
    doClearTimer: function doClearTimer() {
      clearInterval(this.tipTimer);
      this.tipTimer = null;
      if (this.titleOld) {
        window.document.title = this.titleOld;
      }
      this.tipTimerCount = 0;
    },
    doPageTitle: function doPageTitle(msg, type) {
      var _this = this;
      type = type || '提醒';
      if (this.tipTimer) {
        this.doClearTimer();
      }
      this.titleOld = document.title;
      this.tipTimerCount = 0;
      this.tipTimer = setInterval(function () {
        _this.tipTimerCount++;
        if (_this.tipTimerCount % 2 === 0) {
          window.document.title = '【' + type + '】' + msg;
        } else {
          window.document.title = msg;
        }
        // if (this.tipTimerCount > 6) {
        //   this.doClearTimer()
        // }
      }, 500);
    },
    doPlaySound: function doPlaySound() {
      var audio = this.$refs.audio;
      if (!audio) {
        return;
      }
      try {
        audio.pause();
        audio.play();
      } catch (e) {}
    }
  }
};