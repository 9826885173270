/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
var accountRouter = {
  path: '/account',
  component: Layout,
  children: [{
    path: 'accountInfo',
    name: 'AccountInfo',
    component: function component() {
      return import('@/views/org/account/accountInfo');
    },
    meta: {
      title: '账号管理',
      icon: 'list',
      roles: ['org:shopAccount']
    }
  }]
};
//  {
//   path: '/account',
//   component: Layout,
//   name: 'account',
//   alwaysShow: true,
//   meta: {
//     title: '账号管理',
//     icon: 'zhanghao',
//     roles: ['account']
//   },
//   children: [
//     {
//       path: 'accountInfo',
//       component: () => import('@/views/org/account/accountInfo'),
//       name: 'AccountInfo',
//       meta: { title: '账号授权', icon: 'list', roles: ['org:shopAccount'] },
//     },
//   ]
// }

export default accountRouter;