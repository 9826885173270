var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        styles: { top: "120px", width: "1160px" },
        width: "1160",
        visible: _vm.flag,
        "mask-closable": false,
        scrollable: "",
      },
      on: {
        close: _vm.clickClose,
        "update:visible": function ($event) {
          _vm.flag = $event
        },
      },
    },
    [
      _vm.flag
        ? [
            _vm.goodsFlag
              ? _c("goodsDialog", {
                  ref: "goodsDialog",
                  attrs: { selectedWay: _vm.goodsData },
                  on: {
                    selected: function (val) {
                      _vm.goodsData = val
                    },
                  },
                })
              : _c("linkDialog", {
                  staticClass: "linkDialog",
                  on: {
                    selectedLink: function (val) {
                      _vm.linkData = val
                    },
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  _vm.flag = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.clickOK },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }