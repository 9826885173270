import request from '@/utils/request';
import requestJson from '@/utils/requestJson';
export function getOrderPage(data) {
  return request({
    url: '/small/page',
    method: 'get',
    params: data
  });
}
export function getLogPage(data) {
  return request({
    url: '/sync/log/getPage',
    method: 'get',
    params: data
  });
}
export function getTypePage(data) {
  return request({
    url: '/small/type/page',
    method: 'get',
    params: data
  });
}
export function uploadType(data) {
  return request({
    url: '/small/uploadType',
    method: 'post',
    data: data
  });
}
export function uploadExcel(data) {
  return request({
    url: '/small/uploadCsv',
    method: 'post',
    data: data
  });
}
export function getShopType() {
  return request({
    url: '/small/getShopType',
    method: 'get'
  });
}
export function exportExcel(data) {
  return fetch({
    url: '/small/exportExcel',
    method: 'get',
    timeout: '120000',
    responseType: 'blob',
    data: data
  });
}
export function typeSave(data) {
  return request({
    url: '/small/type/save',
    method: 'post',
    data: data
  });
}
export function typeUpdateById(data) {
  return request({
    url: '/small/type/updateById',
    method: 'post',
    data: data
  });
}
export function deleteTypeById(id) {
  return request({
    url: "/small/type/deleteById/".concat(id),
    method: 'post'
  });
}
export function deleteById(id) {
  return requestJson({
    url: "/small/deleteById",
    method: 'post',
    data: {
      ids: id
    }
  });
}
export function getListByTypeId(id) {
  return request({
    url: "/small/sku/getListByTypeId/".concat(id),
    method: 'get'
  });
}
export function skuSave(data) {
  return request({
    url: '/small/sku/save',
    method: 'post',
    data: data
  });
}
export function skuUpdateById(data) {
  return request({
    url: '/small/sku/updateById',
    method: 'post',
    data: data
  });
}
export function skuDeleteById(id) {
  return request({
    url: "/small/sku/deleteById/".concat(id),
    method: 'post'
  });
}
export function count(shopId) {
  return request({
    url: "/small/count?shopId=" + shopId,
    method: 'get'
  });
}
export function shopCount(shopId, startTime, endTime) {
  return request({
    url: "/small/shopCount?shopId=" + shopId + '&startTime=' + startTime + '&endTime=' + endTime,
    // ?uid="+this.uid+'&'+'shopId='+this.shopId
    method: 'get'
  });
}
export function shopCountList(shopId, startTime, endTime) {
  return request({
    url: "/small/shopCountList?shopId=" + shopId + '&startTime=' + startTime + '&endTime=' + endTime,
    // ?uid="+this.uid+'&'+'shopId='+this.shopId
    method: 'get'
  });
}
export function asyncOrder() {
  return request({
    url: "/hubStudio/syncOrder",
    method: 'post'
  });
}
export function asyncTemuOrder(val) {
  return requestJson({
    url: "/hubStudio/syncTemuOrder",
    method: 'post',
    data: {
      temuIds: val
    }
  });
}
export function temuShipList() {
  return requestJson({
    url: "/hubStudio/temuShipList",
    method: 'post'
  });
}
export function batchShip(ids) {
  return request({
    url: "/small/batchShip?ids=" + ids,
    method: 'post'
  });
}
export function temuPlatformDelivery(ids) {
  return requestJson({
    url: "/small/temuPlatformDelivery",
    method: 'post',
    data: {
      orderIds: ids
    }
  });
}
export function syncProduct(ids) {
  return request({
    url: "/small/product/syncProduct?ids=" + ids,
    method: 'post'
  });
}
export function syncDraftProduct(ids, tikTokIds) {
  return request({
    url: "/small/product/syncDraftProduct?ids=" + ids + "&tikTokIds=" + tikTokIds,
    method: 'post'
  });
}
export function getTikTokListInfo(ids) {
  return request({
    url: "/small/product/getTikTokListInfo",
    method: 'post'
  });
}
export function shipNoSync(ids) {
  return request({
    url: "/small/shipNoSync?ids=" + ids,
    method: 'post'
  });
}
export function shipNoSyncTemu(ids) {
  return request({
    url: "/small/shipNoSyncTemu?ids=" + ids,
    method: 'post'
  });
}
export function toShip(data) {
  return request({
    url: "/small/updateShipNo",
    method: 'post',
    data: data
  });
}
export function syncShipNo(data) {
  return request({
    url: "/small/toShip",
    method: 'post',
    data: data
  });
}
export function updateStatus(data) {
  return request({
    url: "/small/updateStatus",
    method: 'post',
    data: data
  });
}
export function updateStatusBatch(data) {
  return request({
    url: "/small/updateStatusBatch",
    method: 'post',
    data: data
  });
}
export function updateWarehouseIdBatch(data, warehouseId) {
  return request({
    url: "/small/updateWarehouseIdBatch?ids=" + data + "&warehouseId=" + warehouseId,
    method: 'post'
  });
}
export function updateAddress(data) {
  return request({
    url: "/small/updateAddress",
    method: 'post',
    data: data
  });
}
export function cancelOrder(orderId) {
  return request({
    url: "/small/cancelOrder?orderId=" + orderId,
    method: 'post'
  });
}
export function smallProductDeleteById(id) {
  return request({
    url: "/small/product/deleteById/".concat(id),
    method: 'post'
  });
}
export function getMainInd() {
  return request({
    url: "/member/shop/getMainInd",
    method: 'get'
  });
}
export function getSubordinateShopList() {
  return request({
    url: "/member/shop/getSubordinateShopList",
    method: 'get'
  });
}
export function getShopList() {
  return request({
    url: "/member/shop/getShopList",
    method: 'get'
  });
}
export function getSupplierInfo() {
  return request({
    url: "/small/getSupplierInfo",
    method: 'get'
  });
}
export function warehouseInvGetPage(data) {
  return request({
    url: "/warehouse/warehouseInv/getPage",
    method: 'get',
    params: data
  });
}
export function getShipOrder(data) {
  return request({
    url: "/small/getShipOrder",
    method: 'get',
    params: data
  });
}
export function createShipOrder(data) {
  return requestJson({
    url: "/small/createShipOrder",
    method: 'post',
    data: data
  });
}
export function product(ids) {
  return request({
    url: "/small/product/apply/product?ids=" + ids,
    method: 'post'
  });
}
export function applylist(data) {
  return request({
    url: "/small/product/apply/getPage",
    method: 'get',
    params: data
  });
}

// export function auditproduct(data) {
//   return request({
//     url: `/small/product/audit/product`,
//     method: 'post',
//     data: data
//   })
// }

export function auditproduct(ids, auditStatus, auditReason) {
  return request({
    url: "/small/product/audit/product?ids=" + ids + '&auditStatus=' + auditStatus + '&auditReason=' + auditReason,
    method: 'post'
  });
}
export function ShipOrdercancel(orderNo) {
  return request({
    url: "/small/shipOrder/cancel?orderNo=" + orderNo,
    method: 'post'
  });
}
export function countAddressExceptionNum() {
  return request({
    url: "/small/countAddressExceptionNum",
    method: 'get'
  });
}
export function addressValid(data) {
  return request({
    url: "/small/addressValid",
    method: 'post',
    data: data
  });
}
export function exportShipInfo(orderIds) {
  return request({
    url: '/small/exportShipInfo?orderIds=' + orderIds,
    method: 'get'
  });
}
export function getShipResultByOrderIds(ids) {
  return request({
    url: '/small/getShipResultByOrderIds?ids=' + ids,
    method: 'get'
  });
}
export function smallupdateSku(data) {
  return requestJson({
    url: '/small/updateSku',
    method: 'post',
    data: data
  });
}
export function smallsave(data) {
  return requestJson({
    url: "/small/save",
    method: 'post',
    data: data
  });
}
export function smallsaveLabel(data) {
  return requestJson({
    url: "/small/saveLabel",
    method: 'post',
    data: data
  });
}
export function getWarehouseBySku(data) {
  return requestJson({
    url: "/small/getWarehouseBySku",
    method: 'post',
    data: data
  });
}
export function smallSkuOrder(data) {
  return requestJson({
    url: "/small/pageSkuOrder",
    method: 'get',
    params: data
  });
}
export function uploadSkuOrder(data) {
  return request({
    url: '/small/uploadSkuOrderExcel',
    method: 'post',
    data: data
  });
}
export function skuOrderDelete(id) {
  return request({
    url: "/small/skuOrderDelete/".concat(id),
    method: 'post'
  });
}
export function skuOrderUpdate(data) {
  return requestJson({
    url: "/small/skuOrderUpdate",
    method: 'post',
    data: data
  });
}
export function skuOrderRetry(ids) {
  return request({
    url: "/small/skuOrderRetry?ids=" + ids,
    method: 'post'
  });
}
export function getShipSkuListByTitle(data) {
  return requestJson({
    url: "/small/getShipSkuListByTitle",
    method: 'get',
    params: data
  });
}
export function getOrderCount() {
  return requestJson({
    url: "/small/getOrderCount",
    method: 'get'
  });
}
export function splitOrder(data) {
  return requestJson({
    url: "/small/splitOrder",
    method: 'post',
    data: data
  });
}
export function splitOrderWarehouse(data) {
  return requestJson({
    url: "/small/splitOrderWarehouse",
    method: 'post',
    data: data
  });
}
export function incomeCalculate(data) {
  return requestJson({
    url: "/small/incomeCalculate",
    method: 'post',
    data: data
  });
}
export function getLinkWarehouse() {
  return request({
    url: "/small/getLinkWarehouse",
    method: 'post'
  });
}
export function getLogisticsTracks(data) {
  return requestJson({
    url: "/small/getLogisticsTracks",
    method: 'post',
    data: data
  });
}