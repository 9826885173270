var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "wrap-bottom-men wanl-chat-mini flex f-a-c f-j-c",
          on: {
            click: function ($event) {
              return _vm.handleIm()
            },
          },
        },
        [
          _c("div", { staticClass: "water1" }),
          _vm._v(" "),
          _c("div", { staticClass: "water2" }),
          _vm._v(" "),
          _c("div", { staticClass: "water3" }),
          _vm._v(" "),
          _c("img", { attrs: { src: require("./chat_mini.png") } }),
          _vm._v(" "),
          _vm.totalUnReadNum > 0
            ? _c(
                "span",
                {
                  staticClass:
                    "chat-num flex f-a-c f-j-c padding-lr2 f10-size t-color-w bg-color-r b-radius-30",
                },
                [_vm._v(_vm._s(_vm.totalUnReadNum))]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "el-drag-dialog", rawName: "v-el-drag-dialog" }],
          attrs: {
            title: "",
            visible: _vm.imDialog,
            modal: false,
            "close-on-click-modal": false,
            width: "850px",
            height: "640px",
            "close-on-press-escape": true,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.imDialog = $event
            },
          },
        },
        [
          _c("lemon-imui", {
            ref: "IMUI",
            attrs: {
              user: _vm.user,
              hideMenu: true,
              hideMessageName: true,
              hideMessageTime: true,
              "contact-contextmenu": _vm.contactContextmenu,
              contextmenu: _vm.contextmenu,
            },
            on: {
              "pull-messages": _vm.handlePullMessages,
              send: _vm.handleSend,
              "change-contact": _vm.handleChangeContact,
              "message-click": _vm.handleMessageClick,
            },
            scopedSlots: _vm._u([
              {
                key: "sidebar-message-fixedtop",
                fn: function () {
                  return [_c("span")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("audio", { ref: "chataudio", attrs: { src: require("./ding.mp3") } }),
      _vm._v(" "),
      _c("notice", { ref: "noticeFlash" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }