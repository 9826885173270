import request from '@/utils/request';
import notokenrequest from '@/utils/notokenrequest';
export function loginByUsername(username, password, brandId) {
  var data = {
    username: username,
    password: password,
    brandId: brandId
  };
  return request({
    url: '/common/login',
    method: 'post',
    data: data
  });
}
export function logout() {
  return request({
    url: '/common/logout',
    method: 'post'
  });
}
export function getUserInfo() {
  return request({
    url: '/member/shop/get',
    method: 'get'
    // params: { token }
  });
}
export function getUser(token) {
  return request({
    url: '/member/getUser',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function refreshToken() {
  return request({
    url: '/common/refreshToken',
    method: 'post'
  });
}
export function register(data) {
  return notokenrequest({
    url: '/common/register',
    method: 'POST',
    data: data
  });
}
export function getCodeImg() {
  return request({
    url: '/common/captcha',
    method: 'get'
  });
}
export function getCodePhone(data) {
  return request({
    url: '/common/sendCode',
    method: 'POST',
    data: data
  });
}
export function phoneLogin(data) {
  return request({
    url: '/common/forgetPassword',
    method: 'POST',
    data: data
  });
}
export function setPassword(data) {
  return request({
    url: '/member/setPassword',
    method: 'POST',
    data: data
  });
}
export function changeRole(data) {
  return request({
    url: '/member/changeRole',
    method: 'post',
    params: data
  });
}
export function getAllRole() {
  return request({
    url: '/member/getAllRole',
    method: 'get'
  });
}
export function shopAccountregister(data) {
  return notokenrequest({
    url: '/member/external/shopAccount/register',
    method: 'post',
    data: data
  });
}
export function invitationCode() {
  return request({
    url: '/member/invitation/code',
    method: 'post'
  });
}