var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "list" },
      _vm._l(Object.keys(_vm.promotionList), function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "list-item",
            class: { active: _vm.selectedIndex == index },
            on: {
              click: function ($event) {
                return _vm.clickPromotion(item, index)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.typeOption(item).title) + "\n    ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _vm.showPromotionList
        ? _c("div", [
            _c(
              "div",
              { staticClass: "tables" },
              [
                _c("el-table", {
                  attrs: {
                    height: "350",
                    border: "",
                    tooltip: "",
                    loading: _vm.loading,
                    columns: _vm.activeColumns,
                    data: _vm.showPromotionList,
                  },
                }),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totals > 0,
                      expression: "totals>0",
                    },
                  ],
                  attrs: {
                    total: _vm.totals,
                    page: _vm.params.page,
                    limit: _vm.params.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.params, "page", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.params, "pageSize", $event)
                    },
                    pagination: _vm.init,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }