var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-tabs",
        { staticClass: "tabs", attrs: { value: _vm.wap[0].title } },
        _vm._l(_vm.wap, function (item, i) {
          return _c(
            "el-tab-pane",
            {
              key: i,
              attrs: { label: item.title, name: item.title },
              on: {
                click: function ($event) {
                  return _vm.clickTag(item, i)
                },
              },
            },
            [
              _c(_vm.templateWay[item.name], {
                ref: "lili-component",
                refInFor: true,
                tag: "component",
                on: {
                  selected: function (val) {
                    _vm.changed = val
                  },
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }