import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getGoodsCateList } from '@/api/goods';
export default {
  data: function data() {
    return {
      parentIndex: '',
      // 分类父级下标
      secondIndex: '',
      // 分类二级下标
      thirdIndex: '',
      // 分类三级下标
      categoryList: [],
      // 分类列表一级数据
      secondLevel: [],
      // 分类列表二级数据
      thirdLevel: [],
      // 分类列表三级数据
      selectedData: "",
      // 已选分类数据
      props: {
        label: 'name',
        value: 'id',
        children: 'children',
        checkStrictly: true
      }
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    // 点击一级
    handleClickChild: function handleClickChild(item, index) {
      this.parentIndex = index;
      this.secondLevel = item.children;
      item.___type = "category";
      item.allId = item.id;
      this.secondIndex = '';
      this.thirdIndex = '';
      this.thirdLevel = [];
      this.$emit("selected", [item]);
      // 点击第一级的时候默认显示第二级第一个
      // this.handleClickSecondChild(item.children, 0);
    },
    // 点击二级
    handleClickSecondChild: function handleClickSecondChild(second, index) {
      second.___type = "category";
      second.allId = "".concat(second.parentId, ",").concat(second.id);
      this.secondIndex = index;
      this.thirdLevel = second.children;
      this.thirdIndex = '';
      this.$emit("selected", [second]);
      // this.handleClickthirdChild(second.children[0], 0);
    },
    // 点击三级
    handleClickthirdChild: function handleClickthirdChild(item, index) {
      item.___type = "category";
      item.allId = "".concat(this.categoryList[this.parentIndex].id, ",").concat(item.parentId, ",").concat(item.id);
      this.$emit("selected", [item]);
      this.thirdIndex = index;
    },
    init: function init() {
      var _this = this;
      getGoodsCateList().then(function (response) {
        _this.categoryList = response.data;
        _this.categoryList.forEach(function (item) {
          item.___type = "category";
        });
      });
    }
  }
};