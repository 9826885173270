import Layout from '@/layout';
var warehouseRouter = {
  path: '/warehouse',
  component: Layout,
  redirect: '/warehouse/list',
  name: 'warehouse',
  alwaysShow: true,
  meta: {
    title: '仓库管理',
    icon: 'report-brand',
    roles: ['warehouse']
  },
  children: [
  // {
  //   path: 'list',
  //   component: () => import('@/views/warehouse/list/index'),
  //   name: 'warehouseList',
  //   meta: { title: '仓库列表', role: [] }
  // },
  // {
  //   path: 'inv',
  //   component: () => import('@/views/warehouse/inv/index'),
  //   name: 'warehouseInv',
  //   meta: { title: '仓库库存', role: [] }
  // },
  {
    path: 'address',
    component: function component() {
      return import('@/views/warehouse/address/index');
    },
    name: 'Address',
    meta: {
      title: '仓库地址',
      role: ['warehouse:address']
    }
  }]
};
export default warehouseRouter;