import "core-js/modules/es6.function.name";
var title = process.env.VUE_APP_TITLE || '';
import store from '@/store';
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    var new_pageTitle = '';
    if (pageTitle == '萤火货盘') {
      new_pageTitle = store.state.user.name.substr(0, 2) + "货盘";
    } else {
      new_pageTitle = pageTitle;
    }
    return "".concat(new_pageTitle, " - ").concat(title);
  }
  return "".concat(title);
}